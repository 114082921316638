import JshModule from '@core/scripts/helper/jsh-module';

do {
    const {moduleScope, config} = JshModule('teaser/info-i');
    if (!config.entryId) continue;
    const dialog = document.getElementById(`content-platform__info-i__content-${config.entryId}`);
    if (!dialog) continue;
    moduleScope.querySelector('button')?.addEventListener('click', () => {
        dialog.showModal();
    });
    const jsCloseBtns = dialog.querySelectorAll('.close-dialog');
    jsCloseBtns.forEach((button) => {
        button.addEventListener('click', (e) => {
            e.preventDefault();
            dialog.close();
        });
    });
} while (document.querySelector('[jsh-module="teaser/info-i"]') !== null);
